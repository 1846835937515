	/*
  	Flaticon icon font: Flaticon
  	Creation date: 24/02/2021 06:09
  	*/

	@font-face {
	  font-family: "Flaticon";
	  src: url("../assets/fonts/Flaticon.eot");
	  src: url("../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	    url("../assets/fonts/Flaticon.woff2") format("woff2"),
	    url("../assets/fonts/Flaticon.woff") format("woff"),
	    url("../assets/fonts/Flaticon.ttf") format("truetype"),
	    url("../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	  font-weight: normal;
	  font-style: normal;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
	  @font-face {
	    font-family: "Flaticon";
	    src: url("../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	  }
	}

	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	  font-family: Flaticon;
	  font-size: 18px;
	  font-style: normal;
	  margin-left: 0;
	}

	.flaticon-home:before {
	  content: "\f100";
	}

	.flaticon-patient:before {
	  content: "\f101";
	}

	.flaticon-doctor:before {
	  content: "\f102";
	}

	.flaticon-alarm:before {
	  content: "\f103";
	}

	.flaticon-scissors:before {
	  content: "\f104";
	}

	.flaticon-financial:before {
	  content: "\f105";
	}

	.flaticon-user:before {
	  content: "\f106";
	}

	.flaticon-medical:before {
	  content: "\f107";
	}

	.flaticon-loupe:before {
	  content: "\f108";
	}

	.flaticon-notification:before {
	  content: "\f109";
	}

	.flaticon-bell:before {
	  content: "\f10a";
	}

	.flaticon-plus:before {
	  content: "\f10b";
	}

	.flaticon-minus-sign:before {
	  content: "\f10c";
	}

	.flaticon-star:before {
	  content: "\f10d";
	}

	.flaticon-star-1:before {
	  content: "\f10e";
	}

	.flaticon-filter:before {
	  content: "\f10f";
	}

	.flaticon-cloud-computing:before {
	  content: "\f110";
	}

	.flaticon-clock:before {
	  content: "\f111";
	}

	.flaticon-quote:before {
	  content: "\f112";
	}

	.flaticon-right-quotation-sign:before {
	  content: "\f113";
	}

	.flaticon-next:before {
	  content: "\f114";
	}

	.flaticon-left-arrow:before {
	  content: "\f115";
	}

	.flaticon-pencil:before {
	  content: "\f116";
	}

	.flaticon-pencil-1:before {
	  content: "\f117";
	}

	.flaticon-menu:before {
	  content: "\f118";
	}